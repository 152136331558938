<template>
	<div class="mt-5">
		<h2 class="nutriyou-h3 text-center primary--text">Le nostre recensioni</h2>
		<div class="text-center mb-3 poppins-regular">
			Scopri cosa dicono di noi persone
			che hanno provato Nutriyou!
		</div>

		<v-carousel height="auto" hide-delimiters :show-arrows="true" v-if="$vuetify.breakpoint.smAndDown">
			<template v-slot:prev="{ on, attrs }">
				<v-btn icon class="primaryBackground" v-bind="attrs" v-on="on">
					<v-icon color="white">fas fa-angle-left</v-icon>
				</v-btn>
			</template>
			<template v-slot:next="{ on, attrs }">
				<v-btn icon class="primaryBackground" v-bind="attrs" v-on="on">
					<v-icon color="white">fas fa-angle-right</v-icon>
				</v-btn>
			</template>
			<v-carousel-item v-for="review in Reviews" :key="review.ReviewId" class="px-15">
				<ReviewCard :review="review"></ReviewCard>
			</v-carousel-item>
		</v-carousel>
		<v-container v-else>
			<v-row dense>
				<v-col cols="4" v-for="review in Reviews" :key="review.ReviewId">
					<ReviewCard :review="review"></ReviewCard>
				</v-col>
			</v-row>
		</v-container>

	</div>
</template>

<script>
import ReviewCard from "./ReviewCard";
export default {
	components: {
		ReviewCard
	},
	data() {
		return {
			Reviews: [
				{
					ReviewId: 1,
					text: `Non conoscevo Nutriyou , ho visto la pubblicità su Istagram ero molto scettica perché avevo già fatto un percorso online senza avere risultati, ho compilato il questionario per curiosità e per capire se si trattava delle solite cose, ho schiacciato il tasto INVIA per sbaglio, però alla fine sono contenta di aver sbagliato perché Gabriele ha fatto la differenza, con luo mi sono subito trovata bene, ha capito da subito le mie esigenze.
Il servizio è stato molto positivo e anche il prezzo.`,
					author: "Monica S.",
					DateStr: "2 Giugno 2024",
					Rating: 5
				},
				{
					ReviewId: 2,
					text: `Gabriele è molto competente e disponibile.
Stiamo facendo insieme un percorso più ampio di un semplice regime dietetico.
Consigliato`,
					author: "Valentina",
					DateStr: "1 Giugno 2024",
					Rating: 5
				},
				{
					ReviewId: 3,
					text: `Celeste è empatica, ascolta e propone sempre nuove soluzioni.
					Mi trovo benissimo e ogni incontro è una scoperta. Super raccomandata!`,
					author: "Vanessa",
					DateStr: "8 Febbraio 2024",
					Rating: 5
				}
			]
		};
	}
}
</script>
<style lang="scss">
@import "@/assets/css/variables.scss";

.primaryBackground{
	background-color: $primary!important;
}
</style>
