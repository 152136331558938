<template>
	<div class="BorderedBox rounded pa-5">
		<div class="text-center mb-3">
			<v-rating empty-icon="far fa-star" full-icon="fas fa-star" half-icon="fas fa-star-half-alt" length="5"
				half-increments color="amber accent-3" dense size="24" :value="review.Rating" readonly></v-rating>
		</div>
		<div class="text-center primary--text font-weight-normal TitleFont mb-3" style="font-size: 1.3rem;">
			{{ review.author }}
		</div>
		<div class="text-body-2 mb-3 poppins-regular">
			{{ review.text }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		review: {
			type: Object,
			required: true,
		},
	}
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.BorderedBox{
	background-color: $ny_blueLight;
	border: 2px solid $ny_blue!important;
}
</style>
